import gewiLogo from "../assets/landing-2.png";
import LoadingSpinner from "../components/LoadingSpinner";

const Maintenance = () => {
  return (
    <>
    
    <div style={{position:"absolute", top:'20%'}}  className="w-full text-center text-2xl">
    Estamos trabajando en una nueva actualización...
    </div>
    


      <div className="flex items-center justify-center h-screen">
        <div className="">
        

          {/* Your content here */}
          <img src={gewiLogo} className="h-8" />
          <LoadingSpinner />

          {/* Success Bar */}
          <div className="bg-white rounded-xl shadow-sm overflow-hidden p-1">
            <div className="relative h-6 flex items-center justify-center">
              <div className="absolute top-0 bottom-0 left-0 rounded-lg w-[95%] bg-green-200" />
              <div className="relative text-green-900 font-medium text-sm">
                95%
              </div>
            </div>
          </div>
          {/* END Success Bar */}
        </div>
      </div>
    </>
  );
};

export default Maintenance;
