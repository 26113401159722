import "./loadingspinner.css";

const LoadingSpinner = () => {
  return (
    // <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-75 z-50">
      <div className="square-container">
        <div className="square">
          <div className="rectangle top-left"></div>
          <div className="rectangle top-right"></div>
          <div className="rectangle bottom-left"></div>
          <div className="rectangle bottom-right"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
