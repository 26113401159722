import "./App.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Solutions from "./pages/Solutions";
import AboutUsPage from "./pages/AboutUsPage";
import DemoPage from "./pages/DemoPage";
import HomePage from "./pages/HomePage";
import PricePage from "./pages/PricePage";
import Maintenance from "./pages/Maintenance";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="bg-white">
      <Router>
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<Maintenance />} />
          <Route path="/whyGewi" element={<Maintenance />} />
          <Route path="/solutions" element={<Maintenance />} />
          <Route path="/price" element={<Maintenance />} />
          <Route path="/aboutUs" element={<Maintenance />} />
          {/* TODO: Improve this router */}
          <Route path="/demo/" element={<Maintenance />} />
          <Route path="/demo/:email" element={<Maintenance />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
